import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

// NOTE:
// Some of these fields are not necessarily needed here (eg, district, link,)
// but adding them here makes it easy to just copy and paste the results from the redash query.
// The non-essential fields are flagged as optional.
// And I used snake casing for the same reason.
export const FCM_DM_PROFILE_RESPONSIVENESS_SCORE_DATA = defineFcm('dm_profile_responsiveness_score_data')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			optional: true,
			shape: {
				type: 'object',
				optional: true,
				shape: {
					state: { type: 'string' },
					decisionMakers: {
						type: 'array',
						shape: {
							type: 'object',
							shape: {
								petition_target_id: { type: 'string' },
								name: { type: 'string', optional: true },
								state: { type: 'string' },
								district: { type: 'string', optional: true },
								total_relevant_petitions: { type: 'number' },
								responded_to_petitions: { type: 'number' },
								responsiveness_score: { type: 'number' },
								dm_profile_link: { type: 'string', optional: true },
							},
						},
					},
				},
			},
		}),
	)
	.withDefault([])
	.toConfig();
