import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_DM_PROFILE_RESPONSIVENESS_SCORE_CONFIG = defineFcm('dm_profile_responsiveness_score_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				contact: {
					type: 'object',
					shape: {
						email: { type: 'string' },
						phone: { type: 'string' },
					},
				},
				tags: {
					type: 'array',
					optional: true,
					shape: {
						type: 'object',
						shape: {
							min: { type: 'number' },
							max: { type: 'number' },
							label: { type: 'string' },
							color: { type: 'string' },
						},
					},
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
