import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_HIDE_NAV_SAP_BUTTON_PER_PAGE = defineFcm('hide_nav_sap_button_per_page')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			shape: {
				type: 'enum',
				values: [
					'petition-details',
					'sap',
					'homepage',
					'account-settings-cookie-prefs',
					'login_or_join',
					'user-profile',
					'petition-comments',
					'browse',
					'starter-dashboard',
					'search',
					'petitionActions',
					'account-settings',
					'petition-update',
					'legal-policy',
					'membership-join',
				] as const,
			},
		}),
	)
	.withDefault([])
	.toConfig();
